.ratings-count-container {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: white;
}

.totales{
    color: white;
    margin-left: 10px;
    font-size: 1.5rem;
}

.area-select-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
}

label{
    font-weight: bold;
}

.custom-select {
    padding: 8px 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    transition: 0.3s;
}

.custom-select:hover,
.custom-select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.emojis-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center; /* Centra horizontalmente */
    align-items: center;    /* Centra verticalmente */
    align-content: center;  /* Asegura la alineación vertical total */
    margin: 0 auto;         /* Centra el contenedor en la página */
}


.card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.icon {
    margin-bottom: 10px;
}

.emoji-icon {
    font-size: 2.5rem;
}

.grin { color: limegreen }
.smile { color: #f1f13c; }
.meh { color: #ff9900; }
.frown { color: #e74c3c; }
.angry { color: #2C3E50; }

.card-content h4 {
    margin: 0;
    font-size: 1.2rem;
    color: #555;
}

.card-content p {
    margin: 5px 0 0;
    color: #777;
    font-size: 1rem;
}

.total-summary {
    margin: 15px auto;
    font-size: 1.2rem;
    color: #555;
    text-align: center;
}

.total-summary span {
    font-weight: bold;
    color: #333;
}

.no-data-message {
    text-align: center;
    color: #999;
    font-size: 1rem;
}

/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F0F0F0;
    padding: 10px 20px;
    color: white;
}

.navbar-logo {
    width: 13rem;
}

.navbar-right {
    display: flex;
    gap: 20px;
}

.navbar-link {
    color: #5F5F5F;
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.3s;
}

.navbar-link:hover {
    color: black;
}

.navbar-link {
    padding: 0.7em 1.7em;
    border-radius: 0.5em;
    background: #e8e8e8;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    transition: all 0.3s;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.logout-button {
    color: #ff4d4d;
}

.navbar-link:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

/* Estilos responsivos */
@media (max-width: 768px) {
    .navbar-right {
        display: none; /* Oculta los enlaces de navegación */
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 20px;
        background-color: #F0F0F0;
        padding: 10px;
        border-radius: 5px;
        width: 150px;
    }

    .navbar-right.open {
        display: flex;
    }

    .navbar-link {
        font-size: 1rem;
        padding: 5px 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        font-size: 1.2rem;
        color: #5F5F5F;
        background: none;
        border: none;
    }
}

/* En pantallas grandes, oculta el icono de hamburguesa */
@media (min-width: 769px) {
    .hamburger {
        display: none;
    }
}
/* Contenedor principal de la tabla de usuarios */
.usuarios-container {
    padding: 0 18px;
    overflow-x: auto;
}

h2 {
    color: #4a90e2;
    text-align: center;
}

/* Icono de agregar usuario */
.addUserIcon {
    cursor: pointer;
    display: inline-block;
    font-size: 1.3rem;
    align-items: end;
    margin: auto 25px;
    padding: 8px;
    border-radius: 50%;
    color: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    background-color: #4a90e2;
}

.addUserIcon:hover {
    background-color: white;
    color: #013971;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Tabla de usuarios */
.usuarios-table {
    width: 100%;
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 1em;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    overflow: hidden;
}

/* Encabezado de la tabla */
.usuarios-table thead tr {
    background-color: #2C3E50;
    text-align: center;
    color: #ffffff;
}

.usuarios-table th, .usuarios-table td {
    padding: 12px 15px;
    border: 1px solid #dddddd;
    text-align: center;
}

/* Estilos para filas alternas en el cuerpo de la tabla */
.usuarios-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Botones de edición y eliminación */
.edit-button, .delete-button {
    font-size: 1.8rem;
    padding: 5px 8px;
    margin: 0 4px;
    border: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.edit-button {
    color: green;
}

.delete-button {
    color: red;
}

.edit-button:hover, .delete-button:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .usuarios-table th, .usuarios-table td {
        padding: 10px 8px;
        font-size: 0.9rem;
    }

    .addUserIcon {
        font-size: 1.5rem;
    }

    .edit-button, .delete-button {
        font-size: 1.5rem;
    }
}

@media (max-width: 576px) {
    /* Vista tipo tarjeta para pantallas extremadamente pequeñas */
    .usuarios-table, .usuarios-table thead, .usuarios-table tbody, .usuarios-table th, .usuarios-table td, .usuarios-table tr {
        display: block; /* Convierte la tabla en tarjetas */
    }

    .usuarios-table th {
        display: none; /* Oculta encabezados en pantallas pequeñas */
    }

    .usuarios-table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .usuarios-table td::before {
        content: attr(data-label); /* Etiquetas para cada celda en vista de tarjeta */
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
        color: #5F5F5F;
    }
}

dialog {
    width: 80%;
    max-width: 500px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-items: center;
    background-color: #f7f9fc;
    padding: 20px;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.closeIcon{
    position: absolute;
    bottom: 10px;
    right: 50%;
    font-size: 1.5rem;
    color: #5F5F5F;
}
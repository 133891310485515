.feedback-container {
    width: 100%;
    margin-top: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feedback-container label {
    font-weight: bold;
    margin-bottom: 5px;
}

.feedback {
    width: 40rem; /* Aumentamos el ancho */
    min-height: 15px; /* Aumentamos la altura */
    padding: 12px;
    font-size: 16px;
    border: 2px solid #5F6264;
    border-radius: 8px;
    outline: none;
    transition: border 0.3s ease-in-out;
    resize: vertical; /* Permitir que el usuario ajuste la altura */
}

.feedback:focus {
    border-color: #0056b3;
}

.submit-button {
    margin-top: 15px;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    background-color: #013971;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.1s;
}

.submit-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
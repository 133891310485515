.table-container {
    width: 100%;
    margin: 30px 10px;
    padding: 0 16px;
    overflow-x: auto;
}

.evaluation-table {
    width: 100%;
    min-width: 50px;
    border-collapse: collapse;
    font-family: "Roboto", sans-serif;
}

.evaluation-table th {
    cursor: pointer;
    padding: 5px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.evaluation-table th:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #1557c0;
    transform: translateY(-1px);
}

.evaluation-table th, .evaluation-table td {
    padding: 0.8rem;
    text-align: center;
    border: 1px solid #e0e0e0;
    font-size: 0.9rem !important;
}

.evaluation-table th {
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #2C3E50;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.evaluation-table tbody tr {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition: transform 0.15s ease-in-out;
}

.evaluation-table tbody tr:hover {
    transform: scale(1.01);
    background-color: #f8f9fa;
    cursor: pointer;
}

.evaluation-table td {
    color: #555;
    font-weight: 500;
}

.evaluation-table tbody tr:last-child td {
    border-bottom: none;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 850px) {
    .table-container row {
        width: 90%;
    }
    .evaluation-table {
        font-size: 0.7rem;
    }
    .evaluation-table th {
        font-size: 0.8rem;
    }
}

@media (max-width: 790px) {
    .evaluation-table {
        font-size: 0.8rem;
        width: 100%;
    }
    .table-container {
        width: 95%;
    }
}

@media (max-width: 550px) {
    .table-container {
        width: 90%;
    }
    .evaluation-table {
        font-size: 0.7rem;
    }
    .evaluation-table th {
        font-size: 0.8rem;
    }
}
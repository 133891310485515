.container {
    text-align: center;
    align-items: center;
    margin: 20px auto auto;
    width: 80%;
}

.logo {
    aspectRatio: 1.5;
    max-width: 45%;
    max-height: 45%;
    margin-bottom: 20px;
}

h1{
    margin-top: 40px;
    font-size: 2rem;
}

.button-container {
    font-size: 1rem;
}

.btn:hover{
    color: white;
}
.btnAdmon:hover{
    background: #014786;
}
.btnAlmacen:hover{
    background: #1f1f1f;
}
.btnRecepcion:hover{
    background: #268fbe;
}
.btnComedor:hover{
    background: #8ca38b;
}
.btnDireccion:hover{
    background: #4f2d4b;
}
.search-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.searchBtn {
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 5px 8px;
    cursor: pointer;
    transition: box-shadow 0.3s, transform 0.3s;
}

.searchIcon {
    font-size: 2.1rem;
    font-weight: bold;
}

.searchBtn:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
    color: #013971;
    background-color: white;
}

.searchInput {
    position: absolute;
    right: 60px; /* Ajusta la posición del input */
    height: 35px;
    width: 0;
    opacity: 0;
    border: 1px solid #013971;
    border-radius: 20px;
    padding: 0 10px;
    color: #013971;
    background-color: white;
    transition: width 0.5s ease, opacity 0.5s ease;
    font-size: 1rem;
    outline: none;
    pointer-events: none; /* Evita que sea clickeable cuando está colapsado */
    visibility: hidden; /* Oculta el input cuando está colapsado */
}

.searchInput.expanded {
    width: 200px; /* Ancho expandido */
    opacity: 1;
    visibility: visible; /* Asegura que el input sea visible */
    pointer-events: auto; /* Activa clickeable cuando está expandido */
}

/* Ratings.css */
.container {
  text-align: center;
  align-items: center;
  margin: 20px auto auto;
  width: 80%;
  }
  
  h1 {
    font-family: 'Arial', sans-serif;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #013971;
  }
  
  .input-field {
    width: 100%;
    height: 1.8rem;
    font-size: 22px;
    padding: 10px;
    margin-top: 25px;
    margin-bottom: 20px;
    border: 2px solid #ccc;
    border-radius: 50px;
    text-align: center;
  }
  
  .emoji-container {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-bottom: 25px;
    color: #5F5F5F;
  }

  .emoji-container svg {
    margin-top: 25px;
    font-size: 5rem;
    cursor: pointer;
  }
  
  .selected {
    color: #013971;
  }
  
  .emoji {
    font-size: 3rem;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .emoji:hover {
    transform: scale(1.2);
  }
  
  .emoji.selected {
    transform: scale(1.3);
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
  }
  
  /* Colores fijos para cada emoji */
  .emoji:nth-child(1) {
    color: green; /* Excelente */
  }
  
  .emoji:nth-child(2) {
    color: limegreen;
  }
  
  .emoji:nth-child(3) {
    color: rgb(241, 241, 60);
  }
  
  .emoji:nth-child(4) {
    color: orange;
  }
  
  .emoji:nth-child(5) {
    color: red;
  }
  
  .submit-btn {
    margin-top: 25px;
    font-size: 1.8rem;
    background-color: #316e33;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
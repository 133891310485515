/* Home.css */ 
.btn {
    position: relative;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1em 1.5em;
    display: inline-block;
    cursor: pointer;
    border-radius: 6em;
    transition: all 0.2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;
    margin: 3%;
  }
  
  .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
  
  .btn::after {
    background-color: #fff;
  }
  
  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }
  
  .container-homeButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 20vh; /* Contenedor de altura completa de la ventana */
  }
  
  /* Estilos para el botón */
  .homeButton {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #e3e1e1;
    color: white;
    border: none;
    border-radius: 30%;
    cursor: pointer;
    font-size: 22px;
  }
  
  #button-Cmd:hover {
    background-color: #35972c;
    color: #fff;
  }
  #button-Clt:hover {
    background-color: #5F5F5F;
    color: #fff;
  }
  #button-Rcp:hover {
    background-color: #205b85;
    color: #fff;
  }
#button-Wc:hover {
    background-color: #af1a1a;
    color: #fff;
}

.login-icon {
    position: absolute;
    right: 50px;
    top: 50px;
    font-size: 2rem;
    color: #b0b0b0;
    cursor: pointer;
    transition: color 0.3s ease;
}

.login-icon:hover {
    color: #808080;
}

/* Estilos para AdminView */
.header {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 99%;
    background: #013971;
    margin: 10px auto;
    border-radius: 0.5em;
    font-size: 1.2rem;
}

.welcome {
    display: flex;
    margin-left: 20px;
    color: white;
    font-weight: bold;
}

.welcomeIcon{
    margin-right: 20px;
}

.row, .rowCount {
    display: flex;
    justify-content: center;
    text-align: center;
}

.rowCount {
    width: 100%;
}

/* Estilos para columnas en pantallas grandes */
.column {
    flex: 50%;
    padding: 0 5px;
    margin: 0 5px;
}

/* Limita la altura de los contenedores y agrega scroll */
.tableContainer, .ratingsCount, .rowGraph {
    max-height: 80vh;
    overflow-y: auto;

    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.ratingsCount {
    margin-top: 5px;
}

/* Media query para pantallas pequeñas */
@media (max-width: 790px) {
    .row {
        flex-direction: column;
        align-items: center;
    }

    .column {
        flex: 100%;
        margin: 10px 0;
        width: 93%;
    }

    .ratingsCount{
        width: 93%;
    }
}